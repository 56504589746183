import React, { Component } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class App extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg bg-primary fixed-top navbar-transparent " color-on-scroll={400}>
          <div className="container">
            <div className="navbar-translate">
              <a className="navbar-brand" href="https://emiils.com"  title="Emils Notes" data-placement="bottom" > Emiils.com</a>
                
              <button className="navbar-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-bar top-bar" />
                <span className="navbar-toggler-bar middle-bar" />
                <span className="navbar-toggler-bar bottom-bar" />
              </button>
            </div>
            <div className="collapse navbar-collapse justify-content-end" id="navigation" data-nav-image="./assets/img/blurred-image-1.jpg">
              <ul className="navbar-nav">
                
              
                <li className="nav-item">
                  <a className="nav-link"  title="Follow us on Twitter" data-placement="bottom" href="" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon="fab fa-twitter" />
                    <p className="d-lg-none d-xl-none">Twitter</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" title="Like us on Facebook" data-placement="bottom" href="" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-square" />
                    <p className="d-lg-none d-xl-none">Facebook</p>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link"  title="Follow us on Instagram" data-placement="bottom" href="" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram" />
                    <p className="d-lg-none d-xl-none">Instagram</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* End Navbar */}
        <div className="wrapper">
          <div className="page-header clear-filter" filter-color="orange">
            <div className="page-header-image" data-parallax="true" style={{backgroundImage: 'url("./backgound.jpg")'}}>
            </div>
            <div className="container">
              <div className="content-center brand">
              
               
              </div>
              <h6 className="category category-absolute"></h6>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default App;
